import { Tag } from './tag.model';
import { Display } from './display.model';
import { Layout } from './layout.model';

export enum DeviceWarning {
    NONE = "NONE",
    NOCAMPAIGN = "NOCAMPAIGN",
}

export class Device {
    id: number;
    name: string;
    clientName: string;
    state: string;
    tags: Array<Tag>;
    type: string;
    store: string;
    branch: string;
    preview: {
        large: {
            url: string;
        },
        small: {
            url: string;
        }
    };
    resolution: {
        width: number,
        height: number
    };
    startTime: string;
    endTime: string;
    campaigns: any[];
    displays: Array<Display>;
    layout: string;
    layoutDetail: Layout;
    htmlPlayerCode: string;
    geolocation: Array<number>;
    netconf: {
        ipLocal: string;
    };
    _warnings: DeviceWarning[];
}
