<form  [formGroup]="dateFormGroup" class="campaign-date" >
    <div  class="campaign-date__daterange">
        <div  class="campaign-date__daterange__container">
            <div class="campaign-date__startdate">
                <h3 i18n>Začátek kampaně</h3>
                <div class="campaign-date__form-row">
                    <mat-form-field>
                        <mat-label i18n>Datum</mat-label>
                        <input matInput [matDatepicker]="startDatePicker" i18n-placeholder placeholder="Vyberte datum" [max]="campaign.untilStop ? '' : campaign.endDate" (click)="startDatePicker.open()" formControlName="startDate">
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                            <mat-icon matDatepickerToggleIcon color="primary">date_range</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker ></mat-datepicker>
                    </mat-form-field>
                    <span i18n> v </span>
                    <ngx-mat-timepicker-field formControlName="startTime"  [format]="24" [defaultTime]="campaign.startTime"></ngx-mat-timepicker-field >
                </div>
            </div>

            <div class="campaign-date__enddate">
                <h3 i18n>Konec kampaně</h3>
                <div class="campaign-date__form-row">
                    <div class="campaign-date__form-row" style="margin-right: 20px" *ngIf="!campaign.untilStop">
                        <mat-form-field >
                            <mat-label i18n>Datum</mat-label>
                            <input matInput [matDatepicker]="endDatePicker" [min]="campaign.startDate"  i18n-placeholder placeholder="Vyberte datum" (click)="endDatePicker.open()" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                                <mat-icon matDatepickerToggleIcon color="primary">date_range</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker  #endDatePicker></mat-datepicker>
                        </mat-form-field>
                        <span i18n> v </span>
                        <ngx-mat-timepicker-field formControlName="endTime"  [format]="24" [defaultTime]="campaign.endTime"></ngx-mat-timepicker-field >
                        <span i18n> a </span>
                        <div class="campaign-date__action-after-end">
                            <mat-form-field  class="mat-form-field--fullwidth">
                                <mat-label i18n>Akce po skončení</mat-label>
                                <mat-select formControlName="actionAfterEnd">
                                    <mat-option [value]="'StopCampaign'" i18n>Pouze ukončit kampaň</mat-option>
                                    <mat-option [value]="'DeleteCampaign'" i18n>Smazat kampaň</mat-option>
                                    <mat-option [value]="'DeleteCampaignWithContent'" i18n>Smazat kampaň i obsah</mat-option>
                                    <mat-option [value]="'DeleteCampaingArchiveContent'" i18n>Smazat kampaň a archivovat obsah</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="campaign-date__end-toggle">
                        <mat-slide-toggle  color="primary" formControlName="untilStop" i18n>Do zrušení</mat-slide-toggle>
                    </div>
                </div>
            </div>
        </div>
        <div class="campaign-date__dateinfo" i18n>*Datum začátku nesmí být větší než datum konce kampaně.</div>
    </div>
    <div class="campaign-date__daytime " *ngIf="campaign.type!='fixed'">
        <h3 i18n>Vyjímky v denním opakování</h3>
        <div  class="campaign-date__form-row">
            <mat-slide-toggle i18n-matTooltip matTooltip="Kampaň se bude přehrávat běhěm dne jen ve zvoleném čase" class="campaign-date__toggle" color="primary" formControlName="useDayTime" i18n>Pouze v čase</mat-slide-toggle>
            <div class="campaign-date__form-row campaign-date__daytime__forms" *ngIf="campaign.useDayTime ">
                <span i18n> od </span>
                <ngx-mat-timepicker-field formControlName="dayStartTime"  [format]="24" [defaultTime]="campaign.dayStartTime"></ngx-mat-timepicker-field >
                <span i18n> do </span>
                <ngx-mat-timepicker-field formControlName="dayEndTime"  [format]="24" [defaultTime]="campaign.dayEndTime"></ngx-mat-timepicker-field >
            </div>
        </div>
    </div>
    
    <div class="campaign-date__days">
        <h3 i18n>Vyjímky v týdenním opakování</h3>
        <div  class="campaign-date__form-row ">
            <mat-slide-toggle i18n-matTooltip matTooltip="Kampaň se bude přehrávat jen ve zvolených dnech" class="campaign-date__toggle" color="primary" formControlName="useDaySelection" i18n>Pouze ve dnech</mat-slide-toggle>
            <div  class="campaign-date__form-row " *ngIf="campaign.useDaySelection" >
                <label  class="campaign-date__days__day" formArrayName="days" *ngFor="let day of daysArray['controls']; let i = index">
                    <mat-checkbox  color="primary" [formControlName]="i" [value]="i.toString()" [name]="i.toString()" >{{days[i].name}}</mat-checkbox>
                </label>
            </div>
        </div>
    </div>

    <div class="campaign-date__times" *ngIf="campaign.type == 'fixed'">
        <h3 i18n>Fixní opakování behěm dne</h3>
        <button (click)="openTimes()" color="primary" mat-raised-button i18n>Nastavit harmonogram</button>
    </div>
</form>