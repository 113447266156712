<form class="campaign-type" [formGroup]="typeFormGroup" *ngIf="campaign">
    <div class="campaign-type__cards">
        <mat-card  class="campaign-type__card" [ngClass]="(campaign.type=='weight')? 'is-active mat-elevation-z8' : 'mat-elevation-z2'" (click)="changeType('weight')">
            <button color="primary" class="campaign-type__card__ok mat-mdc-mini-fab--small" mat-mini-fab i18n-aria-label aria-label="Vybrána kampaň typu váha" *ngIf="campaign.type=='weight'">
                <mat-icon>done</mat-icon>
            </button>
            <mat-card-content >
                <mat-icon class="campaign-type__card__icon" color="primary" aria-hidden="false">equalizer</mat-icon>
                <mat-card-title i18n>Priorita přehrávání</mat-card-title>
                <mat-card-subtitle i18n>Přehrávání se bude opakovat podle zvolené priority</mat-card-subtitle>
            </mat-card-content>
            <mat-card-actions class="campaign-type__card__action"  *ngIf="campaign.type=='weight'">
                <span class="campaign-type__card__label" i18n> malá </span>
                <mat-slider
                    color="primary"
                    [max]=100
                    [min]=1
                    showTickMarks 
                    discrete 
                >
                <input matSliderThumb formControlName="weight">
                </mat-slider>
                <span class="campaign-type__card__label" i18n> velká </span>
            </mat-card-actions>
        </mat-card>

        <mat-card class="campaign-type__card" [ngClass]="(campaign.type=='interval')? 'is-active mat-elevation-z8' : 'mat-elevation-z2'" (click)="changeType('interval')">
            <button color="primary" class="campaign-type__card__ok mat-mini-fab--small" mat-mini-fab i18n-aria-label aria-label="Vybrána kampaň typu opakování" *ngIf="campaign.type=='interval'">
                <mat-icon>done</mat-icon>
            </button>
            <mat-card-content>
                <mat-icon class="campaign-type__card__icon" color="primary" aria-hidden="false">sync</mat-icon>
                <mat-card-title i18n>Opakování</mat-card-title>
                <mat-card-subtitle i18n>Například každý druhy klip, každý třetí klip atd.</mat-card-subtitle>
            </mat-card-content>
            <mat-card-actions class="campaign-type__card__action" *ngIf="campaign.type=='interval'">
                <span class="campaign-type__card__label" i18n>každé</span>
                <mat-form-field class="input__text input__text--small" style="width: 80px">
                    <input  min=2 max=100 type="number" matInput i18n-placeholder placeholder="Opakování" formControlName="interval" [attr.required]="campaign.type=='interval'">
                </mat-form-field>
                <span class="campaign-type__card__label" i18n>video</span>
            </mat-card-actions>
        </mat-card>

        <mat-card class="campaign-type__card campaign-type__card--fixed" [ngClass]="{'is-active mat-elevation-z8' : campaign.type==='fixed', 'has-error' : !isValid && campaign.type==='fixed' }"  (click)="changeType('fixed')">
            <button color="primary" class="campaign-type__card__ok mat-mini-fab--small" mat-mini-fab i18n-aria-label aria-label="Vybrána kampaň typu fixní" *ngIf="campaign.type=='fixed' && isValid">
                <mat-icon>done</mat-icon>
            </button>
            <button i18n-matTooltip matTooltip="Musíte zadat čas kampaně"  color="warn" class="campaign-type__card__ok mat-mini-fab--small" mat-mini-fab i18n-aria-label aria-label="Chyba při vybrání typu kampaně" *ngIf="campaign.type=='fixed' && !isValid">
                <mat-icon>error</mat-icon>
            </button>
            <mat-card-content>
                <mat-icon class="campaign-type__card__icon" color="primary" aria-hidden="false">access_time</mat-icon>
                <mat-card-title i18n>Fixní čas</mat-card-title>
                <mat-card-subtitle i18n>Video se bude přehrávat v přesně daném čase</mat-card-subtitle>
            </mat-card-content>
            <mat-card-actions  *ngIf="campaign.type=='fixed'" class="campaign-type__card__action campaign-type__card__fixed" >
                <div>
                    <mat-slide-toggle color="primary" formControlName="fixedOneShot" i18n>Pouze jednou</mat-slide-toggle>
                </div>
                <div *ngIf="campaign.fixedOneShot"  class="campaign-type__card__time">
                    <span class="campaign-type__card__label" i18n>Začátek:</span>
                    <div class="ngx-timepicker-wrapper">
                        <ngx-mat-timepicker-field 
                            color="primary"
                            [format]="24"
                            [defaultTime]="'23:11'"
                            formControlName="fixedStartTime"
                            #pickerB>
                        </ngx-mat-timepicker-field>
                    </div>
                </div>
                <div *ngIf="!campaign.fixedOneShot" class="campaign-type__card__fixed">
                    <button (click)="openTimes()" color="primary" mat-raised-button i18n>Časový harmonogram</button>
                </div>
            </mat-card-actions>
        </mat-card>

        <mat-card class="campaign-type__card campaign-type__card--playlist" [ngClass]="{'is-active mat-elevation-z8' : campaign.type==='playlist', 'has-error' : !isValid && campaign.type=='playlist' }" (click)="changeType('playlist')">
            <button color="primary" class="campaign-type__card__ok mat-mini-fab--small" mat-mini-fab i18n-aria-label aria-label="Vybrána kampaň typu playlist" *ngIf="campaign.type=='playlist' && isValid">
                <mat-icon>done</mat-icon>
            </button>
            <button i18n-matTooltip matTooltip="Musíte vybrat nebo vytvořit playlist" color="accent" class="campaign-type__card__ok mat-mini-fab--small" mat-mini-fab i18n-aria-label aria-label="Chyba - není vybrán playlist" *ngIf="campaign.type=='playlist' && !isValid">
                <mat-icon>error</mat-icon>
            </button>
            <mat-card-content>
                <mat-icon class="campaign-type__card__icon" color="primary" aria-hidden="false">playlist_add</mat-icon>
                <mat-card-title i18n>Playlist</mat-card-title>
                <mat-card-subtitle i18n>Zařadí kampaně do playlistu v pevně daném pořadí.</mat-card-subtitle>
            </mat-card-content>
            <mat-card-actions class="campaign-type__card__action" >
                <div *ngIf="campaign.type=='playlist'" class="campaign-type__card__playlist">
                    <app-playlist-select (playlistIdChanged)="updatePlaylist($event)" [playlistId]="campaign.playlistId" ></app-playlist-select>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
</form>