
<mat-card class="device-tile" [ngClass]="{'is-focused' : device.isFocused, 'device-tile--without-campaign' : checkWarning(deviceWarning.NOCAMPAIGN) }"  >
    
    <div (click)="openTimeline()"  >
        <div class="device-tile__overflow"></div>
        <div class="device-tile__image-container" [matTooltip]="'id: ' +device.id" matTooltipPosition="above">
            <img mat-card-image  [src]="device.preview.small.url" i18n-alt alt="Náhled zařízení" (error)="addPlaceholder($event)">
        </div>
        <mat-card-content class="device-tile__title"><span>{{device.name | slice:0:32}}</span></mat-card-content>
    </div>
    <app-device-state  class="device-state" [warnings]="warnings" [state]="device.state" [title]="false" ></app-device-state>
    <button (click)="openPreview()" class="device-tile__preview mat-mdc-mini-fab--small" mat-mini-fab color="primary" i18n-matTooltip [matTooltip]="'Náhled kampaní'" matTooltipPosition="above" i18n-aria-label aria-label="Otevře náhled videi">
        <mat-icon>tv</mat-icon>
    </button>
    <button (click)="openDetail()" class="device-tile__edit mat-mdc-mini-fab--small" mat-mini-fab color="primary" i18n-matTooltip [matTooltip]="'Detail zařízení'" matTooltipPosition="above" i18n-aria-label aria-label="Otevře detail zařízení">
        <mat-icon>edit</mat-icon>
    </button>
</mat-card>