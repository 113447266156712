import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { ContentClip } from "../../models/content-clip.model";
import { ClipsService } from "../clips.service";
import { Tag } from "src/app/models/tag.model";
import { SelectionModel } from "@angular/cdk/collections";
import { catchError, retry } from "rxjs/operators";
import { of as observableOf, of } from "rxjs";
import { UserService } from "src/app/user/user.service";
import { PageEvent } from "@angular/material/paginator";
import { ClipFilters } from "src/app/models/filters.model";

@Component({
  selector: "app-clips-list",
  templateUrl: "./clips-list.component.html",
  styleUrls: ["./clips-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClipsListComponent implements OnInit {
  
  @Input() type = "multiple-actions";
  @Input() category: string = null;
  @Input() selectedClips: ContentClip[] = [];
  @Input() multipleActions? = false;

  @Output() public selected = new EventEmitter();

  clips: ContentClip[];
  selection = new SelectionModel<ContentClip>(true, []);
  selectedAll = false;
  allSelected = false;
  isLoadingResults = false;
  isAdmin = false;
  pageSizeOptions: number[] = [25, 50, 100];
  length = 100;
  pageIndex = 0;
  pageSize = 25;
  filters: ClipFilters = { clientTags: [],  adminTags: [], fulltext: ""};

  constructor(
    private clipsService: ClipsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loadLocalFilters();
    
    this.isAdmin = this.userService.isAdmin;
    this.clipsService.updateClips.subscribe(() => {
      this.fetchClips();
    });
    this.fetchClips();
  }
  fetchClips() {
    this.isLoadingResults = true;
    this.clipsService
      .fetchClipsByCompany({
        offset: this.pageIndex * this.pageSize,
        limit: this.pageSize,
        filter: this.filters,
      })
      .pipe(
        retry(3),
        catchError(() => {
          this.isLoadingResults = false;
          console.log("Error with downloading clips");
          return of();
        })
      )
      .subscribe((response: any) => {
        this.length = parseInt(response.headers.get("X-Rows-Total"));
        if (this.category) {
          this.clips = response.body.filter(
            (clip) => clip.category === this.category
          );
        } else {
          this.clips = response.body;
        }

        this.selectClipsByIds();
        this.isLoadingResults = false;
      });
  }

  selectAll(isSelected: boolean) {
    if (isSelected) {
      this.clips.forEach((clip) => {
        this.selection.select(clip);
      });
    } else {
      this.clips.forEach((clip) => {
        this.selection.deselect(clip);
      });
    }
    this.selected.emit(this.selection.selected);
  }

  areAllselected(): boolean {
    let selection = true;
    let found;

    this.clips.forEach((clip) => {
      found = this.selection.selected.some(
        (selected) => clip.id === selected.id
      );
      if (!found) {
        selection = false;
      }
    });
    return found;
  }

  selectClipsByIds() {
    this.selection.clear();
    if (this.selectedClips.length > 0) {

          this.selectedClips.forEach((clip) => {
             
              const selectedClip =  this.clips.find(item => item.id === clip.id);


              if (selectedClip) {
                // select primary clip list item else select dupicated clips in selectuiobn
                if(!this.selection.isSelected(selectedClip)) {
                   this.selection.select(selectedClip);
                }
                else {
                  this.selection.select(clip);
                }
              } else {
                if(!this.selection.isSelected(clip)) this.selection.select(clip);
              }
          })
      
    }

    this.selected.emit(this.selection.selected);
  }

  selectClip(clip: ContentClip) {
    const isSelected = this.selection.isSelected(clip);
    if (isSelected) {
      this.selection.deselect(clip);
    } else {
      this.selection.select(clip);
    }
    this.selectedAll = this.areAllselected();
    this.selected.emit(this.selection.selected);
  }

  selectCloneClip(clip: ContentClip) {
    this.selection.select(Object.assign({} , clip));
    this.selected.emit(this.selection.selected);
  }

  loadLocalFilters() {
    if(localStorage.getItem("clipFilters")) {
      this.filters = JSON.parse(localStorage.getItem("clipFilters")) as ClipFilters;
    }
  }
  saveLocalFilters() {
    localStorage.setItem("clipFilters", JSON.stringify(this.filters));
  }
  clearLocalFilters() {
    localStorage.removeItem("clipFilters");
  }

  setFilters() {
    if (!this.filters) {return}

  

    if(this.filters.fulltext) {
      this.filterByFulltext(this.filters.fulltext);
    }

    if(this.filters.clientTags.length>0 || this.filters.adminTags.length>0) {
      this.filterByTags([...this.filters.clientTags, ...this.filters.adminTags]);
    }
   
  }
  
  filterByFulltext(filterValue: string) {
    this.selectedClips = this.selection.selected;

    this.filters.fulltext = filterValue.trim().toLowerCase();
    this.fetchClips();
    this.selectedAll = this.areAllselected();
    this.saveLocalFilters();
  }

  filterByTags(filteredTags: Tag[]) {
    this.selectedClips = this.selection.selected;

    this.filters.clientTags = [];
    this.filters.adminTags = [];

    setTimeout(() => {
      if (filteredTags.length > 0) {
        filteredTags.forEach((tag) => {
          if (tag.group == "client") {
            this.filters.clientTags.push(tag);
          }

          if (tag.group == "admin") {
            this.filters.adminTags.push(tag);
          }
        });
      } else {
        this.resetFilters();
      }
      this.fetchClips();

      this.saveLocalFilters();
    }, 10);
  }
  resetFilters() {
    this.selectedClips = this.selection.selected;
    
    this.filters.adminTags = [];
    this.filters.clientTags = [];
    this.filters.fulltext = "";
    this.fetchClips();
    this.clearLocalFilters();
  }
  someSelected(): boolean {
    return (
      this.selection.selected.length > 0 &&
      this.selection.selected.length !== this.clips.length
    );
  }
  multipleActionsChanged(selection) {
    this.selection.clear();
    if (selection) {
      selection.forEach((item) => {
        this.selection.select(item);
      });
    }
  }
  getSelectedIndex(clip: ContentClip) {
    if (this.selection.isSelected(clip)) {
      return this.selection.selected.indexOf(clip) + 1;
    } else {
      return null;
    }
  }
  changePagination(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.fetchClips();
  }
  hasFilters() {
    return this.filters.clientTags.length>0 || this.filters.adminTags.length>0  || this.filters.fulltext.length>0;
  }
  getAllFiltersTags() {
    return [...this.filters.clientTags, ...this.filters.adminTags]
  }
}
