import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Campaign } from "../../models/campaign.model";
import { Playlist } from "src/app/models/playlist.model";
import { CustomUtils } from "src/app/utils/custom-utils.component";
import { MatDialog } from "@angular/material/dialog";
import { CampaignTimeComponent } from "../campaign-time/campaign-time.component";

@Component({
  selector: "app-campaign-type",
  templateUrl: "./campaign-type.component.html",
  styleUrls: ["./campaign-type.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignTypeComponent implements OnInit, AfterViewInit {
  @Input() campaign: Campaign;
  @Output() validated = new EventEmitter();
  typeFormGroup: FormGroup;
  max: string;
  min: string;
  keyInterruption = true;
  playlists: Playlist[] = [];
  isValid: Boolean = false;
  constructor(private fb: FormBuilder, public dialog: MatDialog) {}

  ngOnInit() {
    //backward compatibiity fix
    this.campaign.fixedStartTimes =
      this.campaign.fixedStartTimes == undefined
        ? []
        : this.campaign.fixedStartTimes;
    this.typeFormGroup = this.fb.group({
      weight: new FormControl(this.campaign.weight, Validators.required),
      interval: new FormControl(this.campaign.interval, [Validators.required, Validators.min(2)]),
      fixedStartTime: new FormControl(this.campaign.fixedStartTime),
      playlistId: new FormControl(this.campaign.playlistId),
      fixedOneShot: new FormControl(this.campaign.fixedOneShot),
    });

    this.typeFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }
  ngAfterViewInit() {
    this.checkValidity();
  }

  changeType(type: string) {
    this.campaign.type = type;
    if (this.campaign.type === "playlist") {
      this.typeFormGroup.get("playlistId").setValidators([Validators.required]);
      this.typeFormGroup.get("playlistId").updateValueAndValidity();
    } else {
      this.typeFormGroup.get("playlistId").setValue(null);
      this.typeFormGroup.get("playlistId").setValidators(null);
      this.typeFormGroup.get("playlistId").updateValueAndValidity();
    }
    this.checkValidity();
  }
  get formatLabel(): string {
    return CustomUtils.formatLabel.toString();
  }
  updateDataModel(value) {
    if (value.interval < 2) {
      this.typeFormGroup.get("interval").setValue(2);
      return;
    }
    console.log("value");
    console.log(value);

    this.campaign = Object.assign(this.campaign, value);
    this.checkValidity();
  }

  checkValidity() {
    let message = null;

    if (
      this.campaign.type == "fixed" &&
      !this.campaign.fixedStartTime &&
      this.campaign.fixedStartTimes.length == 0
    ) {
      this.validated.emit({
        status: "INVALID",
        message: "Musíte zadat čas kampaně",
      });
      this.isValid = false;
      return;
    }

    if (this.campaign.type == "playlist" && !this.campaign.playlistId) {
      message = "Musíte vybrat nebo vytvořit playlist";
    }

    this.isValid = !(this.typeFormGroup.status == "INVALID");
    this.validated.emit({ status: this.typeFormGroup.status, message });
  }

  openTimes() {
    const dialogRef = this.dialog.open(CampaignTimeComponent, {
      disableClose: true,
      width: "100%",
      minHeight: "500px",
      maxWidth: "95vw",
      data: {
        times: this.campaign.fixedStartTimes,
        repeatFixedCampaign: this.campaign.repeatFixedCampaign,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.typeFormGroup.get("fixedStartTime").setValue(null);
        this.campaign.fixedStartTime = null;
        this.campaign.fixedStartTimes = result.data.times;
        this.campaign.repeatFixedCampaign = result.data.repeatFixedCampaign;
        this.checkValidity();
      }
    });
  }
  updatePlaylist(playlist: Playlist) {
    if (playlist.id) {
      this.typeFormGroup.get("playlistId").setValue(playlist.id);
    } else {
      this.changeType("weight");
    }
  }
}
