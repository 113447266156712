<form class="device-detail dialog"  [formGroup]="deviceFormGroup" >
    <div mat-dialog-title class="dialog__title">
        <h2 i18n>Detail zařízení</h2>
        <button class="dialog__close" mat-icon-button mat-dialog-close i18n-aria-label aria-label="Zavřít dialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div *ngIf="device">
        <div mat-dialog-content class="dialog__content">
            <mat-tab-group animationDuration="0" class="device-detail__tab" *ngIf="device; else loading">
                <mat-tab>
                    <ng-template mat-tab-label i18n>Základní</ng-template>
                    <ng-template matTabContent>
                        <div class="device-detail__info">
                            <div>
                                <div *ngIf="isAdmin">
                                    <mat-form-field class="mat-mdc-form-field--fullwidth" >
                                        <mat-label i18n>Systémový název zařízení</mat-label>
                                        <input  matInput i18n-placeholder placeholder="Systémový název zařízení" required  formControlName="name">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="mat-mdc-form-field--fullwidth" >
                                        <mat-label i18n>Název zařízení</mat-label>
                                        <input  matInput i18n-placeholder placeholder="Název zařízení" required  formControlName="clientName">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="mat-mdc-form-field--fullwidth" >
                                        <mat-label i18n>Pobočka</mat-label>
                                        <input  matInput i18n-placeholder placeholder="Pobočka"  formControlName="branch">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <app-tag-attach (attached)="attachTag($event)" (removed)="removeTag($event)" [tags]="device.tags | tagsGroupFilter:'client'" [group]="'client'" [type]="'devices'"></app-tag-attach>
                                </div>
                                <div *ngIf="isAdmin">
                                    <app-tag-attach (attached)="attachTag($event)" (removed)="removeTag($event)" [tags]="device.tags | tagsGroupFilter:'admin'" [group]="'admin'" [type]="'devices'"></app-tag-attach>
                                </div>
                                <mat-list >
                                    <mat-list-item *ngIf="device.resolution" i18n> Rozlišení: {{ device.resolution.width }} &#10005; {{ device.resolution.height }}</mat-list-item>
                                </mat-list>
                            </div>
                            <div>
                                <div class="device-detail__preview-url">
                                    <mat-form-field  class="mat-mdc-form-field--fullwidth" >
                                        <mat-label i18n>Náhledová stránka</mat-label>
                                        <input  matInput i18n-placeholder placeholder="Náhled zařízení" disabled [value]="htmlPlayerUrl">
                                    </mat-form-field>
                                    <button (click)="goToUrl(htmlPlayerUrl)" mat-mini-fab color="primary" matSuffix i18n-aria-label aria-label="Otevře náhled zařízení v prohlížeči">
                                        <mat-icon>tv</mat-icon>
                                    </button>
                                </div>
                                <div class="device-detail__preview-wrapper">
                                    <div class="device-detail__preview">
                                        <img [src]="device.preview.large.url" (error)="$event.target['src']='assets/img/placeholders/device-offline.png'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                
                <mat-tab >
                    <ng-template mat-tab-label i18n>Kampaně</ng-template>
                    <ng-template matTabContent>
                        <div class="device-detail__campaigns" *ngIf="device.campaigns">
                            <app-campaigns-list [multipleActions]="false" [short]="true" [checkable]="true" [checkedCampaigns]="campaignIds" (checkCampaign)="changeAttachedCampaign($event)"></app-campaigns-list>
                            <div class="device-detail__right">
                                <h3 i18n>Přiřazené kampaně</h3>
                                <div class="device-detail__attached-campaign-list">
                                    <div  *ngFor="let campaign of device.campaigns" class="device-detail__attached-campaign"  [ngClass]="'device-detail__attached-campaign--' + campaign.state">
                                        <div class="device-detail__attached-campaign__body" >
                                            <div class="device-detail__attached-campaign__text" (click)="openCampaignDetail(campaign.id)">
                                                <span class="device-detail__attached-campaign__id">{{ campaign.id }}</span> 
                                                <span *ngIf="campaign.state !='active'" class="device-detail__attached-campaign__id" i18n>neaktivní</span> 
                                                <h3 class="device-detail__attached-campaign__title">{{ campaign.name }}</h3>
                                            </div>
                                            <div class="device-detail__attached-campaign__actions" >
                                                <button mat-mini-fab class="mat-mdc-mini-fab--small" color="primary" i18n-matTooltip matTooltip="Odebrat kampaň" i18n-aruia-label aria-label="Odebrat kampaň" (click)="removeAttachedCampaing(campaign.id)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab *ngIf="layoutPrivileges">
                    <ng-template mat-tab-label i18n>Rozložení</ng-template>
                    <ng-template matTabContent>
                        <app-layout-selection [selectedLayout]="device.layoutDetail" (selected)="onSelectedLayout($event)"  (layoutUrlChanged)="onlayoutUrlChanged($event)" [layoutUrl]="device.layout"></app-layout-selection>
                    </ng-template>
                </mat-tab>

                <mat-tab *ngIf="layoutPrivileges">  
                    <ng-template mat-tab-label i18n>Lokace</ng-template>
                    <ng-template matTabContent>
                        <div class="device-detail__location" >
                            <div>
                                <mat-form-field class="mat-mdc-form-field--fullwidth" >
                                    <mat-label i18n>Pobočka</mat-label>
                                    <input  matInput i18n-placeholder placeholder="Pobočka"  formControlName="branch">
                                </mat-form-field>
                                <mat-form-field class="mat-mdc-form-field--fullwidth" >
                                    <mat-label i18n>IP adresa</mat-label>
                                    <input  matInput i18n-placeholder placeholder="Lokální ip adresa"  formControlName="ipLocal">
                                </mat-form-field>
                            </div>
                            <div class="device-detail__map">
                                <mat-form-field class="mat-mdc-form-field--fullwidth" >
                                    <mat-label i18n>GPS souřadnice Lat,Lng</mat-label>
                                    <input  matInput i18n-placeholder placeholder="GPS souřadnice"  formControlName="geolocation">
                                </mat-form-field>
                                <app-devices-map *ngIf="device.geolocation" [lat]="device.geolocation[0]" [lng]="device.geolocation[1]" [showDevices]="[device.id]"></app-devices-map>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>

                <!--mat-tab>
                    <ng-template mat-tab-label>Zobrazení</ng-template>
                    <div class="devices__detail__display" >
                        <div>
                            <h3>Nastavení zobrazení</h3>
                        </div>
                        <div>
                        </div>
                    </div>
                </mat-tab-->
                
                <!--mat-tab>
                    <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">watch_later</mat-icon>
                    Pracovní doba
                    </ng-template>

                <div class="devices__detail__nonstop">
                    <mat-slide-toggle color="primary" [(ngModel)]="nonstopPlay">Přehrávat nonstop</mat-slide-toggle>
                </div>

                <div *ngIf="!nonstopPlay" class="devices__detail__day-times">
                    <div class="devices__detail__day-time">
                        <span>Pondělí</span>
                        <app-day-time [startTime]="startTime" [endTime]="endTime"></app-day-time>
                    </div>
                    <div class="devices__detail__day-time">
                        <span>Úterý</span>
                        <app-day-time  [startTime]="startTime" [endTime]="endTime"></app-day-time>
                    </div>
                </div>

            </mat-tab-->

        </mat-tab-group>
    </div>  

    <div mat-dialog-actions class="dialog__footer dialog__footer--reverse">
        <button mat-raised-button (click)="saveDevice()" color="primary" ><span i18n>Uložit</span><mat-icon color="white">check</mat-icon></button>
        <button *ngIf="devicePrivilege && hasAdvancedFunction()" mat-raised-button (click)="removeDevice()" color="transparent"><span i18n>Odebrat zařízení</span><mat-icon color="white">delete</mat-icon></button>
        <!--button mat-raised-button color="primary">Resetovat zařízení<mat-icon color="white">autorenew</mat-icon></button-->
    </div>
</div>
</form>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
