<div class="clip-player" >
    <div class="clip-player__wrapper" >
        <div class="clip-player__video"  *ngIf="clip.clipType === 'video'">
            <video [muted]="muted"  (loadeddata)="onVideoLoaded($event)" [controls]="controls" #videoPlayer (onerror)="onPlayerError($event)" [autoplay]="autoplay">
                <source [src]="clip.source"  [attr.type]="clip.mimeType? clip.mimeType : null" >
            </video> 
        </div>

        <div class="clip-player__image" *ngIf="clip.clipType === 'image'">
            <img  [src]="clip.source" [alt]="clip.name">
        </div>

        <div class="clip-player__video" *ngIf="clip.clipType === 'live' && clip.mimeType == 'application/x-mpegURL'">
            <video [muted]="muted"  (loadeddata)="onVideoLoaded($event)" #videoLivePlayer class="video-js vjs-big-play-centered mini-play" style="width:100%;" >
                <source [src]="clip.source" [attr.type]="clip.mimeType? clip.mimeType : null">
            </video>
        </div>

        <div class="clip-player__youtube" *ngIf="clip.clipType === 'youtube' || clip.mimeType === 'video/x-youtube-live'">
            <iframe  width="100%" height="100%" [src]="url" frameborder="0"  ></iframe>
        </div>

        <div #iframeContainer class="clip-player__iframe" *ngIf="clip.clipType === 'html'" >
            <iframe (load)="calculateScale($event)" #iframe width="1920px" height="1080px" [src]="url" frameborder="0" [ngStyle]="{'transform':'scale('+scaleSize+')'}" ></iframe>
        </div>

        <div  class="clip-player__transcoding"  *ngIf="clip.isTranscoding">
            <app-loading></app-loading>
            <p i18n>Klip se právě zpracovává</p>
        </div>

        <div  class="clip-player__no-preview"  *ngIf="noPreview">
            <mat-icon>cancel_presentation</mat-icon>
            <p i18n>Klip nelze zobrazit v prohlížeči</p>
        </div>
    </div>
</div>
