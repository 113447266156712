import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { DeviceWarning } from "src/app/models/device.model";

@Component({
  selector: "app-device-state",
  templateUrl: "./device-state.component.html",
  styleUrls: ["./device-state.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DeviceStateComponent implements OnInit {
  @Input() state: any = "";
  @Input() title? = true;
  @Input() warnings?:DeviceWarning[] = [];
  deviceWarning= DeviceWarning;

  constructor() {}
  checkWarning(warning:DeviceWarning):boolean {
    return this.warnings.some(item => item==warning)
  }
  ngOnInit() {}
}
